import React, { useEffect } from 'react';
import { Box, Center, Text, Icon,Link } from '@chakra-ui/react'; // Import Chakra UI components
import { FaCheckCircle } from 'react-icons/fa';
import addDataToFirestore from './AddData';
import { UserAuth } from '../context/AuthContext';
import { useCartContext } from '../context/cart_context';
import { Link as RouterLink } from 'react-router-dom';

const SuccessPage = () => {
    const { user, logout } = UserAuth();
    const { cart: cartItems, total_items, total_amount, clearCart } = useCartContext();
    const courseIDs = cartItems.map(course => course.courseID);
    const retrievedObj = JSON.parse(localStorage.getItem('person'));
    
    useEffect(() => {
        const addData = async () => {
          try {
            await addDataToFirestore(retrievedObj.email, courseIDs);
            console.log('Data added to Firestore successfully!');
            clearCart();
          } catch (error) {
            console.error('Error adding data to Firestore:', error);
          }
        };
        addData();
    }, []);
   console.log(retrievedObj.email)
    return (
        <Center h="100vh" bg="gray.100"> 
            <Box p="6" bg="white" rounded="md" shadow="md" textAlign="center"> 
                <Icon as={FaCheckCircle} color="green.500" fontSize="6xl" mb="4" />
                <Text fontSize="4xl" fontWeight="bold" mb="4">Payment Successful!</Text> 
                <Text fontSize="xl" mb="4">Thank you for your purchase. You have successfully bought the courses</Text>
                {/* <ul>
                    {cartItems.map(course => (
                        <li key={course.courseID}>{course.title}</li>
                    ))}
                </ul> */}
                <Link as={RouterLink} to={`/ActiveCourses/${retrievedObj.email}`} color="blue.500" fontSize="2xl" textDecoration="underline">Go to Courses</Link>
              
            </Box>
        </Center>
    );
};

export default SuccessPage;
