import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../data/firebase";

const addDataToFirestore = async (email, courses) => {
  try {
    const userDocRef = doc(db, "Active Users", email);

    // Check if the document already exists
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      // Document exists, retrieve existing Courses array
      const existingData = docSnapshot.data();
      const existingCourses = existingData.Courses || [];
      
      // Append new courses to existing courses
      const updatedCourses = [...existingCourses, ...courses.map(course => ({ Course: `Course ${course}`, Date: new Date() }))];

      // Update the document with the updated Courses array
      await updateDoc(userDocRef, { Courses: updatedCourses });
    } else {
      // Document does not exist, create a new document
      const userData = {
        Courses: courses.map(course => ({ Course: `Course ${course}`, Date: new Date() })),
        Email: email
      };

      await setDoc(userDocRef, userData);
    }

    console.log("Data added to Firestore successfully!");
  } catch (error) {
    console.error("Error adding data to Firestore:", error);
  }
};

export default addDataToFirestore;
