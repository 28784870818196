import React from 'react';
import { useState, useEffect } from 'react';
import { db } from '../data/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { course_images } from '../utils/images';

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF', // Background color of the page
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    width: "auto", // Adjust width as needed
    backgroundColor: 'white', // Light gray background color
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow for depth
    alignItems: 'center',
    textAlign: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1, // Ensure the background image is behind other elements
  },
  text: {
    fontSize: 18,
    color: '#4A4A4A', // Dark gray color
    marginBottom: 10,
    width:"auto",
  },
  courseName: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333333', // Dark color
    marginBottom: 20,
    textDecoration: 'underline',
    width:"auto",
  },
  name: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#333333', // Dark color
    marginBottom: 10,
    width:"auto",
  },
  organization: {
    fontSize: 20,
    color: '#666666', // Gray color
    marginBottom: 10,
    width:"auto",
  },
});

const CertificatePDF = ({ name, collegeName, certificateDate }) => {
  const [obj, setObj] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
  });

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const retrievedObj = JSON.parse(localStorage.getItem('person'));
    if (retrievedObj) {
      setObj(retrievedObj);
    }

    // Fetch user's profile data from the database
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'Users', `${obj.email}`); // Update 'userDocumentId' with the actual document ID
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userProfileData = docSnap.data();
          setFormData(userProfileData);
          console.log('Profile data fetched:', userProfileData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };
    
    if (obj.email) {
      fetchData();
    }
  }, [obj.email]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Content */}
        <View style={styles.contentContainer}>
          {/* Background image */}
          <Image src={course_images.coursebg} style={styles.backgroundImage} />

          <View style={styles.content}>
          <Text style={styles.name}>{formData.name}</Text>
            <Text style={styles.organization}>of {formData.organization}</Text>
            <Text style={styles.text}>for the successful completion of the course</Text>
            <Text style={styles.courseName}>{name}</Text>
            
            
            <Text style={styles.text}>Dated: {certificateDate}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CertificatePDF;
