import React, { useEffect, useState } from 'react';
import { Button, Container, Image, Text, Input } from '@chakra-ui/react';
import { db } from '../data/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import styled from 'styled-components';
function Profile() {
    const [formData, setFormData] = useState({
      name: '',
      age: '',
      organization: '',
      mobileNumber: '',
      email: '',
      education: '',
      researchInterest: '',
      projects: '',
      researchArea: '',
      internships: ''
    });
  
    const [obj, setObj] = useState('');
  
    useEffect(() => {
      // Retrieve data from localStorage when the component mounts
      const retrievedObj = JSON.parse(localStorage.getItem('person'));
      if (retrievedObj) {
        setObj(retrievedObj);
      }
  
      // Fetch user's profile data from the database
      const fetchData = async () => {
        try {
          const docRef = doc(db, 'Users', `${obj.email}`); // Update 'userDocumentId' with the actual document ID
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userProfileData = docSnap.data();
            setFormData(userProfileData);
            console.log('Profile data fetched:', userProfileData);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching document: ', error);
        }
      };
      
      if (obj.email) {
        fetchData();
      }
    }, [obj.email]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  const[isSave,setIsSave]=useState(false);
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        // Save updated profile data to the database
        await setDoc(doc(db, 'Users', `${obj.email}`), formData); 
        console.log('Profile saved successfully!');
        setIsSave(true)
      } catch (error) {
        console.error('Error saving profile:', error);
      }
    };

  return (
    <ProfileWrapper>
    <Container maxW="container.md" bg="white" borderRadius="8px" mt="5px" mb="5px" p="10px" borderColor="black">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3 border-right">
            <div className="d-flex flex-column align-items-center text-center p-3 py-5">
              <Image borderRadius="full" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" alt="Profile" w="150px" />
              <Text fontWeight="bold" fontSize="2xl" mt="30px">{obj?.name || 'User Name'}</Text>
              <Text color="#808080">{obj?.email || 'user@example.com'}</Text>
            </div>
          </div>
          <div className="col-md-5 border-right">
            <div className="p-3 py-5">
            
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="text-right">Profile Settings</h4>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label className="labels">Name</label>
                  <Input type="text" className="form-control" name="name" placeholder="First Name" value={formData.name} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                  <label className="labels">Age</label>
                  <Input type="text" className="form-control" placeholder="Age" name="age" value={formData.age} onChange={handleChange} />
                </div>
               
              </div>
              <div className="row mt-3">
              <div className="col-md-12">
                  <label className="labels">Organization</label>
                  <Input type="text" className="form-control" placeholder="Organization" name="organization" value={formData.organization} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <label className="labels">Mobile Number</label>
                  <Input type="text" className="form-control" placeholder="Enter Phone Number" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <label className="labels">Email ID</label>
                  <Input type="text" className="form-control" placeholder="Enter Email ID" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <label className="labels">Education</label>
                  <Input type="text" className="form-control" placeholder="Education" name="education" value={formData.education} onChange={handleChange} />
                </div>
              
                <div className="col-md-12">
                  <label className="labels">Research Interest</label>
                  <Input type="text" className="form-control" placeholder="Research Interest" name="researchInterest" value={formData.researchInterest} onChange={handleChange} />
                </div>
              
              </div>
             
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 py-5">
              <div className="d-flex justify-content-between align-items-center experience"><span>Bio</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Skills</span></div><br />
              <div className="col-md-12"><label className="labels">Projects</label><Input type="text" className="form-control" placeholder="Projects" name="projects" value={formData.projects} onChange={handleChange} /> <br /></div>
              <div className="col-md-12"><label className="labels">Research Area</label><Input type="text" className="form-control" placeholder="Research Area" name="researchArea" value={formData.researchArea} onChange={handleChange} /></div>
              <div className="col-md-12"><label className="labels">Internships</label><Input type="text" className="form-control" placeholder="Internships" name="internships" value={formData.internships} onChange={handleChange} /></div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <Button fontSize="2xl" type="submit" bg="rgb(99, 39, 120)" color="white" borderRadius="5px" p="10px 20px" cursor="pointer" _hover={{ bg: '#682773' }}>Save Profile</Button>
        </div>
       {isSave && <Text color="green" fontSize="2xl" textAlign="center">Profile Saved Successfully</Text>}
      </form>
    </Container>
    </ProfileWrapper>
  );
}

const ProfileWrapper = styled.div`
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.profile-info {
  text-align: center;
  margin-top: 20px;
}

.profile-info h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.profile-info p {
  color: #808080;
  font-size: 1.2rem;
}

.profile-form {
  margin-top: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.labels {
  font-size: 1.2rem;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 20px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button-save {
  font-size: 1.5rem;
  background-color: rgb(99, 39, 120);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
}

.button-save:hover {
  background-color: #682773;
}

.success-message {
  color: green;
  font-size: 2rem;
  text-align: center;
  margin-top: 20px;
}

`
export default Profile;
