import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../data/firebase";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Text, VStack, Badge, Icon, ButtonGroup, Flex, Radio, Button, Grid, AccordionIcon } from '@chakra-ui/react';
import { useCoursesContext } from '../context/courses_context';
import styled from "styled-components";
import { MdInfo } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { FaShoppingCart } from "react-icons/fa";
import { RiClosedCaptioningFill } from "react-icons/ri";
import StarRating from './StarRating';
import styles from './CourseChapter.module.css'; // Import the CSS module
import { BsPlayFill, BsQuestion, BsLightbulbFill } from 'react-icons/bs';
import { FcIdea } from "react-icons/fc";
import { FaListUl } from 'react-icons/fa';
import { FaVideo } from 'react-icons/fa';
import { BiCheck } from "react-icons/bi";
import { Spinner } from '@chakra-ui/react'
import { FcLock } from 'react-icons/fc';
import CertificatePDF from './CertificatePdf';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

const CourseChapters = () => {
  const { fetchSingleCourse, single_course } = useCoursesContext();
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [course, setCourses] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [video, setVideos] = useState([]);
  const [quizQuestion, setQuizQuestion] = useState([])
  const { id } = useParams();
  // YouTube URL


  // Regular expression to extract video code from YouTube URL
  const fetchCode = (youtubeUrl) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeUrl.match(regex);

    // Extracted video code
    const videoCode = match && match[1];

    return videoCode;

  }

  const { id: courseID, category, image, course_name, description, rating_count, rating_star, students, creator, updated_date, lang, actual_price, discounted_price, what_you_will_learn: learnItems, content, on_Completion } = single_course;
  useEffect(() => {
    fetchSingleCourse(id.charAt(id.length - 1));
  }, []);
  console.log(single_course)
  useEffect(() => {
    const fetchCourses = async () => {
      let i = 1;
      const fetchedCourses = [];

      while (true) {
        const docRef = doc(db, `${id}`, `${i}`);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const c1 = docSnap.data();
          fetchedCourses.push(c1);
        } else {
          // If there's no document for the current course number, break the loop
          break;
        }
        i++;
      }

      setCourses(fetchedCourses);
      setIsFetched(true)
    };

    fetchCourses();
  }, []); // empty dependency array ensures useEffect runs only once


  useEffect(() => {
    const fetchVideo = async () => {
      const fetchedVideos = [];
      const fetchedQuestions = [];

      const docRef2 = doc(db, "Free Videos", `${id}`);
      const docSnap2 = await getDoc(docRef2);

      if (docSnap2.exists()) {
        const videoData = docSnap2.data();
        fetchedVideos.push(videoData);
      }

      const docRef3 = doc(db, "Quiz Questions", `${id}`);
      const docSnap3 = await getDoc(docRef3);

      if (docSnap3.exists()) {
        const questionData = docSnap3.data();
        fetchedQuestions.push(...questionData["Questions"]);
      }

      setVideos(fetchedVideos);

      // Calculate the number of questions to select (50% of total questions)
      const numQuestionsToSelect = 50;

      // Shuffle the array of questions to ensure randomness
      const shuffledQuestions = fetchedQuestions.sort(() => Math.random() - 0.5);

      // Select the first half of the shuffled questions
      const selectedQuestions = shuffledQuestions.slice(0, numQuestionsToSelect);

      setQuizQuestion(selectedQuestions);
    };

    fetchVideo();
  }, []);


  // Function to handle answer check
  console.log(video);
  console.log(quizQuestion);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [pdfGenerated, setPdfGenerated] = useState(false);

  const [answeredQuestions, setAnsweredQuestions] = useState({}); // State to track answered questions
  const [totalScore, setTotalScore] = useState(0);
  useEffect(() => {
    // Check if all questions are answered
    const allQuestionsAnswered = quizQuestion.every(question => answeredQuestions[question.Question]);
    const eligible = quizQuestion.length * 0.4
    if (allQuestionsAnswered && totalScore >= eligible) {
      // Enable Generate Certificate accordion
      setPdfGenerated(true);
    } else {
      // Disable Generate Certificate accordion
      setPdfGenerated(false);
    }
  }, [answeredQuestions, quizQuestion]);
  const handleOptionChange = (question, option) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [question.Question]: option,
    });

    // Mark the question as answered
    setAnsweredQuestions({
      ...answeredQuestions,
      [question.Question]: true,
    });

    // Check if the selected option is correct and update the total score only if the question hasn't been answered before
    if (!answeredQuestions[question.Question] && option === question.Ans) {
      setTotalScore(prevScore => prevScore + 1);
    }
  };

  const handleAnswerCheck = (question) => {
    const selectedOption = selectedAnswers[question.Question];
    const isCorrect = selectedOption === question.Ans;

    return isCorrect;
  };

  let currentDate = new Date().toJSON().slice(0, 10);

  const userDetails = {
    name: course_name,
    collegeName: 'XYZ University',
    certificateDate: currentDate,
  };




  const handleGenerateCertificate = () => {
    // Generate certificate logic goes here
    setPdfGenerated(true); // Set the state to indicate that the PDF is generated
  };

  const handleOpenPdfInNewTab = () => {
    // Open PDF in a new tab
    const pdfBlob = new Blob([<CertificatePDF />], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };

  return (
    <div>
      <SingleCourseWrapper>
        <div className='course-intro mx-auto grid'>
          <div className='course-img'>
            <img src={image} alt={course_name} />
          </div>
          <div className='course-details'>
            <div className='course-category bg-white text-dark text-capitalize fw-6 fs-12 d-inline-block'>{category}</div>
            <div className='course-head'>
              <h1>{course_name}</h1>
            </div>
            <div className='course-body'>
              <p className='course-para fs-18'>{description}</p>
              <div className='course-rating flex'>
                <span className='rating-star-val'><StarRating rating_star={rating_star}></StarRating></span>

                {/* <span className='rating-count fw-5 fs-14'>({rating_count})</span>
              <span className='students-count fs-14'>{students}</span> */}
              </div>

              <ul className='course-info'>
                <li>
                  <span className='fs-14'>Created by <span className='fw-6 opacity-08'>{creator}</span></span>
                </li>
                <li className='flex'>
                  <span><MdInfo /></span>
                  <span className='fs-14 course-info-txt fw-5'>Last updated {updated_date}</span>
                </li>
                <li className='flex'>
                  <span><TbWorld /></span>
                  <span className='fs-14 course-info-txt fw-5'>{lang}</span>
                </li>
                <li className='flex'>
                  <span><RiClosedCaptioningFill /></span>
                  <span className='fs-14 course-info-txt fw-5'>{lang} [Auto]</span>
                </li>
              </ul>
            </div>



          </div>
        </div>

        <div className='course-full bg-white text-dark'>
          <div className='course-learn mx-auto'>

            <div className='course-sc-title'>What you'll learn</div>
            <ul className='course-learn-list grid'>
              {
                learnItems && learnItems.map((learnItem, idx) => {
                  return (
                    <li key={idx}>
                      <span><BiCheck /></span>
                      <span className='fs-14 fw-5 opacity-09'>{learnItem}</span>
                    </li>
                  )
                })
              }
            </ul>
            <div className='course-sc-title'>Note To Students</div>
            <ul className='course-learn-list grid'>
              {
                on_Completion && on_Completion.map((learnItem, idx) => {
                  return (
                    <li key={idx}>
                      <span><BiCheck /></span>
                      <span className='fs-14 fw-5 opacity-09'>{learnItem}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>


        </div>
      </SingleCourseWrapper>

      <div style={{ padding: '20px', color: "black", backgroundColor: "F7F7F7" }}>
        <h1 style={{ fontSize: '3rem', textDecoration: "underline", color: "black", margin: "20px", textAlign: "center" }}>Course Content</h1>
        <div style={{ padding: '10px', backgroundColor: '#ECF9FF' }}>
          <div style={{ marginBottom: '20px' }}>
            {/* Improved Accordion for Free Videos */}
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontSize="2xl">
                      Free Videos <BsPlayFill />
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div style={{ border: '3px solid azure', borderRadius: '10px', backgroundColor: 'white', padding: '20px' }}>
                    {video && video.map((data, index) => (
                      <div key={index} style={{ padding: '10px', background: '#1E3D58', borderRadius: '8px', marginBottom: '10px' }}>
                        <h3 style={{ fontWeight: 'bold', fontSize: '2rem', color: "white" }}>Video Set {index + 1}</h3>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                          {data.Video.map((videoItem, videoIndex) => (
                            <div key={videoIndex} style={{ padding: '10px', background: '#d9d9d9', borderRadius: '8px', marginBottom: '10px' }}>
                              <a href={`https://api-video-4e7bc.web.app/videos/${fetchCode(videoItem)}`} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', fontSize: '1.5rem' }}>

                                <FaVideo size={30} color="blue" style={{ marginRight: '10px' }} />
                                <span style={{ fontWeight: 'bold', fontSize: '2rem', color: "black" }}> Video {videoIndex + 1}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          {/* Improved Accordion for Course Chapters */}
          <Accordion allowMultiple>

            {!isFetched && <div style={{ alignItems: "center", textAlign: "center" }}> <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
            </div>}
            {isFetched && course.map((data, index) => (
              <AccordionItem key={index} borderColor="black" borderWidth="1px" borderRadius="md" mb={4}>
                <AccordionButton justifyContent="space-between">
                  <Box flex="1" display="flex" alignItems="center">
                    <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', margin: 0, color: 'blue' }}>
                      Chapter: {data.Chapter} <FaListUl size={20} />
                    </h2>
                    <Badge variant="solid" colorScheme="blue" fontSize="0.8rem" ml={2}>
                      {data.Lectures.length} Lecture{data.Lectures.length > 1 ? 's' : ''}
                    </Badge>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} borderBottomWidth="1px" borderColor="gray.200">
                  <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={4}>
                    {data.Links && <Box gridColumn="1 / -1" bg="blue.50" p={4} borderRadius="md">
                      <div className="course-content">
                        <div className="links" style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                          <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'blue' }}>Links:</div>
                          <div>{data.Links}</div>
                        </div>

                      </div>
                    </Box>}
                    {data.Lecture_Description && <Box gridColumn="1 / -1" bg="blue.50" p={4} borderRadius="md">
                      <div className="description" style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'blue' }}>Lecture Description:</div>
                        <div>{data.Lecture_Description}</div>
                      </div>
                    </Box>}
                    <Box gridColumn="1 / -1" bg="blue.50" p={4} borderRadius="md" backgroundColor="gray.200" >
                      <div className="lectures" style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: "black" }}>Lectures:</div>
                        <div>
                          {data.Lectures.map((lecture, idx) => (
                            <div key={idx}>
                              <Box border="1px solid black" borderRadius="md" p={2} >
                                <a
                                  href={`https://api-video-4e7bc.web.app/videos/${fetchCode(lecture.Video_link)}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ display: 'flex', color: "black", textDecoration: 'none' }}
                                >
                                  <FaVideo size={20} style={{ marginRight: '10px' }} />
                                  <span style={{ fontSize: '1.7rem' }}>Lecture {idx + 1} : {lecture.Video_Heading} </span>
                                </a>
                              </Box>
                              <Text color="green">Video Description: {lecture.Video_Description}</Text>
                              {/* Add a separator between each video */}
                              {idx !== data.Lectures.length - 1 && <hr style={{ margin: '20px 0', borderTop: '1px solid black' }} />}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Box>

                    {data.Courses && (
                      <Box gridColumn="1 / -1" bg="blue.50" p={4} borderRadius="md">
                        <div className="courses" style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
                          <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'blue' }}>Courses:</div>
                          <ul>
                            {data.Courses.map((course, idx) => (
                              <li key={idx} className="course" style={{ color: 'blue' }}>
                                <span>{course}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Box>
                    )}
                    {data.Alternate_Text && <Box gridColumn="1 / -1" bg="blue.50" p={4} borderRadius="md">
                      <div className="text">
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'blue' }}>Text:</div>
                        <div>{data.Alternate_Text}</div>
                      </div>
                    </Box>}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>



          <Accordion defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box fontSize="2xl">
                    <FcIdea />Quiz Questions
                    {<Badge variant="solid" colorScheme="green" fontSize="1.2rem" ml={2}>
                      Total Score: {totalScore}
                    </Badge>}
                  </Box>
                </AccordionButton>
              </h2>
              <Text color="red" fontSize="xl">Note*:- Complete all Lectures before Attempting the quiz</Text>
              <Text color="red" fontSize="xl">Note*:- In Quiz, option for each question should be chosen once, no multiple attempts are allowed</Text>
              {quizQuestion &&
                quizQuestion.map((question, index) => (
                  <AccordionPanel key={index} pb={4}>
                    <div style={{ border: '6px solid azure', borderRadius: '10px', backgroundColor: '#1E3D58', padding: '20px', color: 'white' }}>
                      <VStack align="flex-start" spacing={4} borderRadius='8px'>
                        <Text fontSize="2xl">Question {index + 1}: {question.Question}</Text>
                        <VStack align="flex-start" spacing={2}>
                          {Object.entries(question).map(([key, value]) => {
                            if (key.startsWith('Op') && value.trim() !== '') { // Check if option is not blank
                              return (
                                <Radio
                                  key={value}
                                  value={value}
                                  onChange={() => handleOptionChange(question, value)}
                                  isChecked={selectedAnswers[question.Question] === value}
                                  isDisabled={answeredQuestions[question.Question]} // Disable options once the question is answered
                                >
                                  <Text fontSize="xl">{value}</Text>
                                </Radio>
                              );
                            }
                            return null;
                          })}
                        </VStack>
                        {/* Display scoring for each question */}
                        {answeredQuestions[question.Question] && (
                          <Text fontWeight="bold" color={handleAnswerCheck(question) ? 'green' : 'red'}>
                            {handleAnswerCheck(question) ? `Correct! You gained 1 point.` : 'Incorrect!'}
                          </Text>
                        )}
                      </VStack>
                    </div>
                  </AccordionPanel>
                ))

              }

            </AccordionItem>
          </Accordion>

          {/* Generate Certificate Accordion */}
          <Accordion defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box fontSize="2xl">
                    Certificate Generation <FcLock />
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Text color="red" fontSize="xl">Note*:- Complete your profile before generating certificate</Text>
                <Text color="red" fontSize="xl">Note*:- Attempt Quiz and score more than 40% to generate certificate</Text>

                {!pdfGenerated ? (
                  <Button isDisabled>Generate Certificate</Button> // Disable button until all questions are answered
                ) : (
                  <PDFDownloadLink document={<CertificatePDF name={userDetails.name} collegeName={userDetails.collegeName} certificateDate={userDetails.certificateDate} />} fileName='Certificate.pdf'>
                    <Button>Download Certificate</Button>
                  </PDFDownloadLink>
                )}
                <Text color="black" fontSize="xl">Quiz Score: {totalScore}/{quizQuestion.length} ({Math.round((totalScore / quizQuestion.length) * 100)}%)</Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>

  );
};

const SingleCourseWrapper = styled.div`
  background-color: DodgerBlue;
  color: var(--clr-white);

  .course-intro{
    padding: 40px 16px;
    max-width: 992px;

    .course-details{
      padding-top: 20px;
    }

    .course-category{
      padding: 0px 18px;
      border-radius: 6px;
    }

    .course-head{
      font-size: 2.3em;
     
      line-height: 1.2;
      padding: 12px 0 0 0;
    }
    .course-para{
      font-size: 1em;
      padding: 12px 0;
    }
    .rating-star-val{
      margin-right: 7px;
      padding-bottom: 5px;
      color: var(--clr-orange);
    }
    .students-count{
      margin-left: 8px;
    }
    .rating-count{
      margin-left: 6px;
      color: #d097f6;
    }
    .course-info{
      li{
        margin-bottom: 2px;
        &:nth-child(2){
          margin-top: 10px;
        }
      }
      .course-info-txt{
        text-transform: capitalize;
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }
    .course-price{
      margin-top: 12px;
      .old-price{
        color: #eceb98;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .course-btn{
      margin-top: 16px;
      .add-to-cart-btn{
        padding: 12px 28px;
        span{
          margin-left: 12px;
        }
      }
    }

    @media screen and (min-width: 880px){
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      .course-details{
        padding-top: 0;
      }
      .course-img{
        order: 2;
      }
    }

    @media screen and (min-width: 1400px){
      grid-template-columns: 60% 40%;
    }
  }

  .course-full{
    padding: 40px 16px;
    .course-sc-title{
      font-size: 22px;
      font-weight: 700;
      margin: 12px 0;
    }
    .course-learn{
      max-width: 992px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-learn-list{
        li{
          margin: 5px 0;
          display: flex;
          span{
            &:nth-child(1){
              opacity: 0.95;
              margin-right: 12px;
            }
          }
        }

        @media screen and (min-width: 992px){
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .course-content{
      max-width: 992px;
      margin-top: 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 28px 22px 28px;

      .course-content-list{
        li{
          background-color: #f7f9fa;
          padding: 12px 18px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 15px;
        }
      }
    }
  }

`;


const InfoItem = styled.li`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export default CourseChapters;