import React from 'react';
import { Container, Heading, Text, Link } from '@chakra-ui/react';

const SupportPage = () => {
  return (
    <Container maxW="container.md" py="10">
      <Heading as="h1" mb="6">Support Page</Heading>

      <Text fontSize="2xl" mb="4">
        If you have any questions or feedback, please feel free to contact us at: {' '}
        <Link href="mailto:adroidconnectz@gmail.com" color="blue.500" textDecoration="underline">adroidconnectz@gmail.com</Link>
      </Text>
    </Container>
  );
}

export default SupportPage;
