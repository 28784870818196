import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const AdroidconnectzPage = () => {
  return (
    <Container maxW="container.md" mt="4" textAlign="center">
      <Heading as="h1" mb="4">Welcome to Adroidconnectz</Heading>
      <Box p="4" borderWidth="1px" borderRadius="lg" boxShadow="md" >
        <Text fontSize="2xl">
          Adroidconnectz is the nation's biggest community of students and mentors, providing the one-stop solution to all the queries related to startups, research internships, higher studies, Innovations, etc. 
        </Text>
        <Text fontSize="2xl" mt="4">
          Adroidconnectz is an online platform to connect students with mentors and provide them with the opportunity to grow and build their career.
        </Text>
        <Text fontSize="2xl" mt="4">
          The team of Adroidconnectz comprises renowned researchers and entrepreneurs. This team motivates students to undertake a career exploration journey and make appropriate career choices at the right time. Adroidconnectz consistently updates students to pace up with the ever-changing dynamism of technology and the environment.
        </Text>
      </Box>
    </Container>
  );
}

export default AdroidconnectzPage;
