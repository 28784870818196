import React from 'react';
import { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import ActiveCourseCard from '../components/ActiveCourseCard';
import { useCoursesContext } from '../context/courses_context';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../data/firebase";
import { Text,Box,Icon,Center } from '@chakra-ui/react';
import { FcReading } from "react-icons/fc";

const ActiveCoursesPage = () => {
  const [sub, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      let i = 1;
      const fetchedCourses = [];

      while (true) {
        const docRef = doc(db, `Course ${i}`, "Detail");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          
          const c1 = docSnap.data();
          const obj = {
            id: `${i}`
          };
          fetchedCourses.push(obj);
        } else {
          // If there's no document for the current course number, break the loop
          break;
        }
        i++;
      }

      setCourses(fetchedCourses);
    };

    fetchCourses();
  }, []); // empty dependency array ensures useEffect runs only once



  const [description, setDescription] = useState('');
  const {email} = useParams();
  const {courses} = useCoursesContext();
  console.log(email)
  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "Active Users", `${email}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Document data:", data);
        const courseNames = data.Courses.map(course => course.Course);
        console.log(courseNames)
        if (data && courseNames) {
          setDescription(courseNames);
        } else {
          console.log("No courseName found in document!");
        }
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, []); // empty dependency array ensures useEffect runs only once

  

console.log("Hello world")
  return (
    <CoursesPageWrapper>
      
  {description &&  
    <div className='container'>
      <div className='category-based-list'>
        {
          courses.filter(course => description.includes(course.CourseId)).map((course) => (
            <ActiveCourseCard key={course.id} {...course} />
          ))
        }
      </div>
    </div>
  }
  {!description && <Center h="100vh" bg="gray.100"> 
            <Box p="6" bg="white" rounded="md" shadow="md" textAlign="center" height="200px" width="400px"> 
                <Icon as={FcReading} color="red.500" fontSize="6xl" mb="4" />
                <Text fontSize="4xl" fontWeight="bold" mb="4">Not enrolled in any course</Text> 
                
            </Box>
        </Center>}
</CoursesPageWrapper>
        
  )
}

const CoursesPageWrapper = styled.div`
  .category-based-list{
    margin-top: 32px;
  }
  @media screen and (min-width: 600px){
    .category-based-list{
      display: grid;
      gap: 26px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (min-width: 992px){
    .category-based-list{
      display: grid;
      gap: 26px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (min-width: 1400px){
    .category-based-list{
      display: grid;
      gap: 26px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

export default ActiveCoursesPage;