import { Box, Center, Text, Link, Icon } from '@chakra-ui/react'; // Import Chakra UI components
import { Link as RouterLink } from 'react-router-dom';
import { MdShoppingCart } from 'react-icons/md';

const CancelPage = () => {
   
    return (
        <Center h="100vh" bg="gray.100"> 
            <Box p="6" bg="white" rounded="md" shadow="md" textAlign="center" height="200px" width="400px"> 
                <Icon as={MdShoppingCart} color="red.500" fontSize="6xl" mb="4" />
                <Text fontSize="4xl" fontWeight="bold" mb="4">Payment Cancelled</Text> 
                <Link as={RouterLink} to={`/cart`} color="blue.500" fontSize="2xl" textDecoration="underline">Go to Cart</Link>
            </Box>
        </Center>
    );
};

export default CancelPage;