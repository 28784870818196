import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const MissionPage = () => {
  return (
    <Container maxW="container.md" mt="4" textAlign="center">
      <Heading as="h1" mb="4">Our Mission</Heading>
      <Box p="4" borderWidth="1px" borderRadius="lg" boxShadow="md">
        <Text fontSize="2xl">
          Our mission is twofold:
        </Text>
        <Text fontSize="2xl" mt="4">
          1. To instill the abilities of a thinker, innovator, and implementor in the student community by providing them with an interactive and learning platform facilitated by mentors. Our aim is to empower students to excel in the fields of research and startups.
        </Text>
        <Text fontSize="2xl" mt="4">
          2. To guide, support, and counsel students in their initial career planning stages. We recognize the importance of early career planning and strive to provide students with the necessary guidance and support to make informed decisions about their future paths.
        </Text>
      </Box>
    </Container>
  );
}

export default MissionPage;
