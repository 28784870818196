import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const VisionPage = () => {
  return (
    <Container maxW="container.md" mt="4" textAlign="center">
      <Heading as="h1" mb="4">Welcome to Our Ecosystem</Heading>
      <Box p="4" borderWidth="1px" borderRadius="lg" boxShadow="md">
        <Text fontSize="2xl">
          Our mission is to provide an ecosystem where students can excel as thinkers, innovators, and implementers. We aim to create a comfortable zone for students and mentors to discuss research, startups, and counseling.
        </Text>
        <Text fontSize="2xl" mt="4">
          At our platform, students have the opportunity to engage with mentors and peers, fostering a collaborative environment for learning and growth. Whether it's exploring new research ideas, launching a startup, or seeking guidance and counseling, our ecosystem is designed to support and empower students every step of the way.
        </Text>
        <Text fontSize="2xl" mt="4">
          We believe that by providing a supportive and nurturing environment, students can unleash their full potential and make meaningful contributions to their communities and the world at large. Join us in building a vibrant and thriving ecosystem where students can thrive and succeed.
        </Text>
      </Box>
    </Container>
  );
}

export default VisionPage;
