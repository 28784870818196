import React from 'react';
import { Container, Heading, Text, Divider } from '@chakra-ui/react';

const FeesAndPayments = () => {
  return (
    <Container maxW="container.md" py="10">
      <Heading as="h1" mb="6">Fees and Payments</Heading>

      <Text fontSize="2xl" mb="4">
        The use of Adroidconnectz App by the Organizations is subject to the applicable service fees (the "Fees"). The Fees and payment terms related to the services provided through Adroidconnectz App are made available on Adroidconnectz App or communicated to the Organizations personally. The Fees are indicated in Indian Rupees (INR) and are charged on a subscription basis monthly or annually. By concluding a service contract with us, the Organization agrees to pay the Fees in accordance with these Terms and the terms and conditions in force at the moment the service contract is concluded. The Fees remain valid for as long as they are indicated on Adroidconnectz App. The Fees are subject to change without prior notice.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Taxes</Heading>
      <Text fontSize="2xl" mb="4">
        The Fees include applicable taxes. The Organizations are responsible for paying all applicable taxes.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Payment Processing</Heading>
      <Text fontSize="2xl" mb="4">
        All payments related to Adroidconnectz App, including payments of the Fees, are processed by our third-party payment gateways. The Payment gateways are solely responsible for handling the payments. The Organization agrees not to hold us liable for payments that do not reach us because it has quoted incorrect payment information or the Payment gateways refused the payment for any other reason.
      </Text>

      <Divider my="6" />

      {/* Add more sections here following the same structure */}
    </Container>
  );
}

export default FeesAndPayments;
