import React from "react";
import styled from "styled-components";
import { other_images } from "../utils/images";

const Hero = () => {
  return (
    <HeroWrapper className="bg-black">
      <div className="container h-100 flex">
        <div className="hero-content ">
          <h1>New to Adroid Connectz?<br></br> Lucky you. </h1>
          <p>Courses start at ₹499. Get your new-student offer before it expires.</p>
        </div>
      </div>
    </HeroWrapper>
  );
};

const HeroWrapper = styled.div`
  background: url(${other_images.hero_img}) center/cover;
  height: 450px;

  .hero-content {
 
    max-width: 400px;
    width: 100%;
    padding: 20px;

    h1 {
      font-size: 32px;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    p {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 600px) {
    
  .hero-content {
 
    max-width: 200px;
    width: 100%;
    padding: 10px;

    h1 {
      font-size: 20px;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    p {
      font-size: 12px;
    }
  }
    background-position: top; /* Adjust background position for better display on smaller screens */
    height: 300px; /* Adjust height for smaller screens */
    padding: 50px 0; /* Adjust padding for smaller screens */
    background-size: cover; /* Make the background image cover the entire container */
  }
`;

export default Hero;
