// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { collection,getDocs,writeBatch,doc} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDHfOpwIST1fUVZnKokrqtITynSYotlIdQ",
    authDomain: "adroiddb.firebaseapp.com",
    projectId: "adroiddb",
    storageBucket: "adroiddb.appspot.com",
    messagingSenderId: "709134526380",
    appId: "1:709134526380:web:06ab7ba2fd7527c00abc09",
    measurementId: "G-Q05KEEYLQ0"
  }; 

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

//code to copy collection

// async function copyCollection(sourcePath, destinationPath) {
//   try {
//     const sourceCollectionRef = collection(db, sourcePath);
//     const querySnapshot = await getDocs(sourceCollectionRef);

//     const batch = writeBatch(db);

//     querySnapshot.forEach((d) => {
//       const data = d.data();
//       const newDocRef = doc(collection(db, destinationPath), d.id);
//       batch.set(newDocRef, data);
//     });

//     await batch.commit();
//     console.log("Collection copied successfully.");
//   } catch (error) {
//     console.error("Error copying collection:", error);
//   }
// }

// console.log("helllo")

// // Specify the source and destination collection paths
// const sourceCollectionPath = "Course 1";
// const destinationCollectionPath = "Course 2";
// copyCollection(sourceCollectionPath, destinationCollectionPath);