import React from 'react';
import {BsStar, BsStarHalf, BsStarFill} from "react-icons/bs";
import styled from "styled-components";

const StarRating = ({rating_star}) => {
  const stars = Array.from({length: 5}, (_, idx) => {
    const val = idx + 0.5;
    return (
      <Star key = {idx}>
        {
          rating_star >= idx + 1 ? (<BsStarFill />) : rating_star >= val ? (<BsStarHalf />) : (<BsStar />)
        }
      </Star>
    )
  })

  return (
    <StarContainer>{stars}</StarContainer>
  )
}

const StarContainer = styled.div`
display: flex; /* Ensure all stars are displayed in one line */
align-items: center;
`;

const Star = styled.span`
  color: #e59819;
`;

export default StarRating