import React from 'react';
import { Container, Heading, Text, Divider } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Container maxW="container.md" py="10">
      <Heading as="h1" mb="6">Introduction</Heading>

      <Text fontSize="2xl" mb="4">
        These General Terms and Conditions (the "General Terms") govern your use of the websites on which they appear at adroidconnectz website and APP and our provision of various online services and resources via the website (the "Services").
      </Text>

      <Text fontSize="2xl" mb="4">
        These General Terms incorporate our Privacy Policy which describes (among other things) how we will use any personal information collected as a result of your use of the Services. For more information, please see our full Privacy Policy. Please read these General Terms (including the Privacy Policy) as well as any Additional Terms carefully, before using any part of our Websites or Services. By using the Websites, you confirm that you accept the General Terms, the Privacy Policy and any relevant Additional Terms and that you agree to comply with them. You should print a copy of these General Terms, the Privacy Policy and any relevant Additional Terms for future reference.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="lg" mb="2">Privacy</Heading>
      <Text fontSize="2xl" mb="4">
        By agreeing to these General Conditions, you agree to the terms of our Privacy Policy, which is expressly incorporated herein. Before using the Site, please carefully review our Privacy Policy. All personal information provided to us as a result of your Access will be handled in accordance with our Privacy Policy, and by Accessing the Site, you consent to the collection, storage, use and dissemination of your personal information in accordance with this Privacy Policy.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="lg" mb="2">Who we are</Heading>
      <Text fontSize="2xl" mb="4">
        The Website and APP is brought to you by Adroid Connectz Pvt. Ltd.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="lg" mb="2">Changes to Terms</Heading>
      <Text fontSize="2xl" mb="4">
        The General Terms and any Additional Terms may be updated by us from time to time. We will post any changes that we make on this page and will indicate at the top of the page for on the first page of any Additional Terms) the date that the relevant terms were last revised. Your continued use of our website and/or Services after any such changes have been made, constitutes your acceptance of the new General Terms and/or Additional Terms If you do not agree to these changes, please do not use or access (or continue to use or access) our website and/or Services.
      </Text>

      <Divider my="6" />

      {/* Add more sections here following the same structure */}

    </Container>
  );
}

export default TermsAndConditions;
