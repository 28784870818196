import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  Divider,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.md" py="10">
      <Heading as="h1" mb="6">Adroid Connectz Privacy Policy</Heading>

      <Text fontSize="2xl" mb="4">
        This statement outlines our privacy practices concerning Personal Information collected and stored through this website, as well as Personal Information provided during participation in our courses, events, and campaigns.
      </Text>

      <Heading as="h2" size="md" mb="2">Personal Information We Collect and Process, and How We Use It</Heading>
      <Text fontSize="2xl" mb="4">
        1. Personal Information that we process For the purposes of this privacy statement, Personal Information is any data which relates to an individual who may be identified from that data, or from a combination of a set of data, and other information which is in possession of Adoidconnectz. In general, you may browse our website without providing any Personal information about yourself. However, we collect certain information such as Information that you provide via our website or APP, including information you provide when you register on our website e.g. name, email address, designation, company, country and telephone number Information about your computer and about your visits to and use of this Site, such as your Internet Protocol (IP) address, demographics, your computers' operating system, and browser type and information collected via cookies Cookie related details are provided in the Cookie Policy. 
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Consequences of Not Providing Personal Information</Heading>
      <Text fontSize="2xl" mb="4">
        If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to provide the corresponding service
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Data Recipients, Transfer, and Disclosure</Heading>
      <Text fontSize="2xl" mb="4">
        Adoidconnectz does not share your Personal information with third parties for marketing purposes without seeking your prior permission. We share your Personal Information within Adoidconnectz or with any of its subsidiaries; Business partners; Service vendors; Authorized third-party agents, of Contractors.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Use of Cookies</Heading>
      <Text fontSize="2xl" mb="4">
        Please refer the Cookie Policy
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Access, Correction, and Objection of Personal Information</Heading>
      <Text fontSize="2xl" mb="4">
        You have the right to access, correct, delete or transfer your Personal Information that we hold, including your profile and preferences. You also have the right to object to certain processing and, where we have asked for your consent to process your Personal information, to withdraw this consent. Where we process your Personal Information because we have a legitimate interest in doing so (as explained above), you also have a right to object to this. These rights may be limited in some situations-for example, where we can demonstrate that we have a legal requirement to process your Personal Information.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Data Security</Heading>
      <Text fontSize="2xl" mb="4">
        Adoidconnectz adopts reasonable and appropriate security practices and procedures including administrative, physical security, and technical controls in order to safeguard your Personal Information.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Data Retention</Heading>
      <Text fontSize="2xl" mb="4">
        Personal information will not be retained for a period more than necessary to fulfill the purposes outlined in this privacy statement unless a longer retention period is required by law or for directly related legitimate business purposes
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Linked Websites</Heading>
      <Text fontSize="2xl" mb="4">
        Our privacy practices regarding Personal Information that we collect and store about you through our portals will be as per the privacy policy of those portals. Adoidconnectz provides links to third-party websites and services. However, Adoidconnectz is not responsible for the privacy statements, practices, or the contents of such third-party websites.
      </Text>

      <Divider my="6" />

      <Heading as="h2" size="md" mb="2">Updates to Privacy Statement</Heading>
      <Text fontSize="2xl" mb="4">
        Adoidconnectz may change the data privacy practices and update this privacy statement as and when the need arises, and the same will be made available on the website. But our commitment to protect the privacy of website users will continue to remain.
      </Text>

      <Text fontSize="2xl" mt="6">
        If you have any questions or concerns about our Privacy Policy, please <Link color="blue.500" textDecoration="underline">contact us</Link>.
      </Text>
    </Container>
  );
}

export default PrivacyPolicy;
