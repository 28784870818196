import hero_img from "../assets/images/hero_img.jpg";
import logo_udemy from "../assets/images/logo_udemy.svg";

import aws_1 from "../assets/images/aws_1.jpg";
import aws_2 from "../assets/images/aws_2.jpg";
import aws_3 from "../assets/images/aws_3.jpg";
import data_science_1 from "../assets/images/data_science_1.jpg";
import data_science_2 from "../assets/images/data_science_2.jpg";
import data_science_3 from "../assets/images/data_science_3.jpg";
import data_science_4 from "../assets/images/data_science_4.jpg";
import data_science_5 from "../assets/images/data_science_5.jpg";
import python_1 from "../assets/images/python_1.jpg";
import python_2 from "../assets/images/python_2.jpg";
import python_3 from "../assets/images/python_3.jpg";
import python_4 from "../assets/images/python_4.jpg";
import python_5 from "../assets/images/python_5.jpg";
import web_dev_1 from "../assets/images/web_dev_1.jpg";
import web_dev_2 from "../assets/images/web_dev_2.jpg";
import web_dev_3 from "../assets/images/web_dev_3.jpg";
import web_dev_4 from "../assets/images/web_dev_4.jpg";
import web_dev_5 from "../assets/images/web_dev_5.jpg";
import marketing_1 from "../assets/images/marketing_1.jpg";
import marketing_2 from "../assets/images/marketing_2.jpg";
import design_1 from "../assets/images/design_1.jpg";
import design_2 from "../assets/images/design_2.jpg";
import cat_aws from "../assets/images/cat_aws.png";
import cat_data_science from "../assets/images/cat_data_science.png";
import cat_web_development from "../assets/images/cat_web_development.png";
import cat_design from "../assets/images/cat_design.png";
import cat_marketing from "../assets/images/cat_marketing.png";
import cat_python from "../assets/images/cat_python.png";
import coursebg from "../assets/images/backgroundCertificate.png"

const course_images = {
  aws_1,
  aws_2,
  aws_3,
  data_science_1,
  data_science_2,
  data_science_3,
  data_science_4,
  data_science_5,
  python_1,
  python_2,
  python_3,
  python_4,
  python_5,
  web_dev_1,
  web_dev_2,
  web_dev_3,
  web_dev_4,
  web_dev_5,
  marketing_1,
  marketing_2,
  design_1,
  design_2,
  coursebg,
};

const other_images = { hero_img, logo_udemy };

const categories_images = [
  cat_python,
  cat_web_development,
  cat_data_science,
  cat_aws,
  cat_design,
  cat_marketing,
];

export { course_images, other_images, categories_images };
