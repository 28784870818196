import React from 'react';
import { useState } from 'react';
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSidebarContext } from '../context/sidebar_context';
import { useCoursesContext } from '../context/courses_context';
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
const Sidebar = () => {
  const navigate = useNavigate();
  const { categories } = useCoursesContext();
  const { closeSidebar, isSidebarOpen } = useSidebarContext();
  const { user, logout } = UserAuth();
  const [isCategoriesOpen, setCategoriesOpen] = useState(false);
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <SidebarWrapper className={`bg-white ${isSidebarOpen ? "show-sidebar" : ""}`}>
      <button type="button" className='sidebar-close-btn' onClick={() => closeSidebar()}>
        <MdClose />
      </button>
      <div className='sidebar-content'>
      
      <button className="toggle-categories" onClick={() => setCategoriesOpen(!isCategoriesOpen)}>
  <span style={{ display: "flex", alignItems: "center" }}>
    <span>Categories</span>
    {isCategoriesOpen ? <MdExpandLess style={{ marginLeft: "5px" }} /> : <MdExpandMore style={{ marginLeft: "5px" }} />}
  </span>
</button>
        {isCategoriesOpen && (
          <ul className='sidebar-category'>
            {categories.map((category, idx) => (
              <li className='sidebar-link-item fw-5' key={idx}>
                <Link to={`category/${category}`}>{category.toUpperCase()}</Link>
              </li>
            ))}
          </ul>
        )}
        
        <hr />
        <ul className='sidebar-link-list'>
        {user ? (
            <div>
            <li className='sidebar-link-item fw-5'>
            {user && <Link className=" login-course-btn " to ={`/ActiveCourses/${user.email}`}>Your Courses</Link>}
            </li>
            <li className='sidebar-link-item fw-5'>
              <div className="sidebar-link-item fw-5" onClick={handleLogout}>Logout</div>
            </li>
            </div>
          ) : (
            <li className='sidebar-link-item fw-5'>
              <Link to='/login'>Login</Link>
            </li>
          )}
          <hr />
        <li className='sidebar-link-item fw-5'>
            <Link to='/about'>About AdroidConnectz</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/mission'>Our Mission</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/vision'>Our Vision</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/privacypolicy'>Privacy Policy</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/refundpolicy'>Payment and Refund Policy</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/termsandconditions'>Terms and Conditions</Link>
          </li>
          <li className='sidebar-link-item fw-5'>
            <Link to='/support'>Support</Link>
          </li>
          
        </ul>
      </div>
    </SidebarWrapper>
  );
}

const SidebarWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
width: 300px;
z-index: 10;
height: 100%;
padding: 30px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
transform: translateX(100%);
transition: var(--transition);

&.show-sidebar {
  transform: translateX(0);
}

.sidebar-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 2px solid var(--clr-black);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
}

.sidebar-close-btn:hover {
  background-color: var(--clr-black);
  color: var(--clr-white);
}

.sidebar-content {
  margin-top: 50px;
  h6 {
    margin-bottom: 16px;
  }
  .sidebar-link-item {
    font-size: 15px;
    margin-bottom: 12px;
    transition: var(--transition);

    &:hover {
      transform: translateX(6px);
      text-decoration: underline;
    }
  }
}
`;

export default Sidebar;
